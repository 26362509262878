import axios from 'axios'
import { Toast } from 'vant';
// import store from '@/store/index'
// import router from '../router';
const request = axios.create({
  // baseURL: '',
  // withCredentials: true,
  headers: {
  },
  // timeout: 5000 // 超时时间
})

// 请求拦截器
request.interceptors.request.use(
  config => {
    return config
  },
  error => {
    // 将异常返回给用户处理
    return Promise.reject(error)
  }
)

// 响应拦截器
request.interceptors.response.use(
  response => {
    return response
  },
  error => {
    Toast.clear();
    Toast(error.message);
    return Promise.reject(error)
  }
)

export default request