import Vue from 'vue'
import Router from 'vue-router'
import { BasicLayout } from '@/layouts';

const Home = () => import('@/pages/Home.vue')
const About = () => import('@/pages/About.vue')

// import loading from '../utils/loading';

Vue.use(Router)

const router = new Router({
    // mode: 'history',
    routes: [
        {
            path: '/',
            component: BasicLayout,
            redirect: '/home/index',
            children: [{
                path: '/home',
                name: 'home',
                component: { render: h => h('router-view') },
                redirect: '/home/index',
                children: [{
                    path: '/home/index',
                    component: Home,
                }]
            }]
        },
        {
            path: '/about',
            component: BasicLayout,
            redirect: '/about/index',
            children: [{
                path: 'index',
                name: 'about',
                component: About
            }]
        },
    ]
})

// 添加路由全局前置守卫
router.beforeEach((to, from, next) => {
    var dom_el = document.querySelector('.dom_el')
    if (dom_el) {
        dom_el.scrollTop = 0;
    }
    // 显示loading组件
    // loading.show()
    next()
})

// 添加路由全局后置守卫
router.afterEach((to, from) => {
    // 隐藏loading组件
    // loading.hide()
})

export default router;
