import Vue from "vue";
import App from "./App.vue";
import axios from "./utils/axios";
import router from "./router";
import store from "./store";
// import i18n from './utils/i18n'
import tools from "./utils/tools";
import {
  Popup,
  Popover,
  Picker,
  Field,
  Button,
  Toast,
  Icon,
  Swipe,
  SwipeItem,
} from "vant";
// 桌面pc应用适配vant
import "@vant/touch-emulator";
import "vant/lib/index.css";
import "./assets/css/reset.css";
import browser from "./assets/js/ResizeHandler";

tools.setRem();

//获取设备
store.dispatch("getBrowser");
var timeBrowserId = null;
window.addEventListener("resize", () => {
  tools.setRem();
  clearTimeout(timeBrowserId);
  timeBrowserId = setTimeout(() => {
    var nowIsH5 = store.getters.getLocBrowser.isH5;
    var newIsH5 = browser();
    if (nowIsH5 !== newIsH5.isH5) {
      store.commit("SET_BROWSER", newIsH5);
      location.reload();
    }
  }, 200);
});

Vue.use(Popup);
Vue.use(Popover);
Vue.use(Picker);
Vue.use(Field);
Vue.use(Button);
Vue.use(Toast);
Vue.use(Icon);
Vue.use(Swipe);
Vue.use(SwipeItem);

// 挂载 axios
Vue.prototype.$axios = axios;

Vue.config.productionTip = false;

new Vue({
  render: (h) => h(App),
  router,
  store,
  // i18n,
}).$mount("#app");
