<template>
  <section class="footer_view" id="footer_view">
    <footer class="layout-desktop-footer" v-if="!browser.isH5">
      <section class="ldf-top">
        <div class="ldf-top-left">
          <p>聯絡方式</p>
          <p>
            ASIA SYS CONSULTING
            PTE.LTD，致力於為移動互聯網用戶提供專業、便捷、豐富、有趣的互聯網文娛生活。
            公司傾力打造優質的互動娛樂產品，特別是高質量的網游精品。
            希望能以優質產品作為橋梁，連接不同年齡層次、興趣偏好的遊戲人群，為用戶搭建健康、輕鬆、益智的休閒文化平台
          </p>
          <p>Email:cs@luckygame88.com</p>
          <p>
            © 2023 ASIA SYS CONSULTING PTE.LTD. All Rights Reserved.
          </p>
          <p>
            ※本遊戲內容涉及牌類及益智娛樂(使用虛擬遊戲幣進行遊戲，且遊戲結果會直接影響虛擬遊戲幣增減之牌類及益智娛樂類遊戲軟體
          </p>
          <p>
            ※本遊戲為免費遊戲，但遊戲內另提供購買虛擬遊戲幣、物品等付費服務，請依個人興趣及能力進行適度消費。
          </p>
          <p>※請注意遊戲時間，長時間進行遊戲容易影響作息，宜適度休息及運動。</p>
        </div>
      </section>
    </footer>

    <section class="layout-mobile-footer" v-if="browser.isH5">
      <section class="lmf-mid">
        <p>聯絡方式</p>
        <p>
          ASIA SYS CONSULTING
          PTE.LTD，致力於為移動互聯網用戶提供專業、便捷、豐富、有趣的互聯網文娛生活。
          公司傾力打造優質的互動娛樂產品，特別是高質量的網游精品。
          希望能以優質產品作為橋梁，連接不同年齡層次、興趣偏好的遊戲人群，為用戶搭建健康、輕鬆、益智的休閒文化平台
        </p>
        <p>Email:cs@luckygame88.com</p>
        <p>© ASIA SYS CONSULTING PTE.LTD. All Rights Reserved.</p>
        <p>
          ※本遊戲內容涉及牌類及益智娛樂(使用虛擬遊戲幣進行遊戲，且遊戲結果會直接影響虛擬遊戲幣增減之牌類及益智娛樂類遊戲軟體
        </p>
        <p>
          ※本遊戲為免費遊戲，但遊戲內另提供購買虛擬遊戲幣、物品等付費服務，請依個人興趣及能力進行適度消費。
        </p>
        <p>※請注意遊戲時間，長時間進行遊戲容易影響作息，宜適度休息及運動。</p>
      </section>
    </section>
  </section>
</template>

<script>
import { mapState, mapMutations, mapActions } from "vuex";
export default {
  name: "GlobalFooter",
  data () {
    return {};
  },
  computed: {
    ...mapState({
      browser: (state) => state.isH5.browser,
    }),
  },
};
</script>

<style lang="less" scoped>
.footer_view {
  .layout-desktop-footer {
    background-color: #533cb3;
    box-sizing: border-box;
    color: rgba(255, 255, 255, 0.9);
    font-size: 14px;
    padding: 58px calc(50% - 494px);
    width: 100%;
    .ldf-top {
      align-items: flex-end;
      display: flex;
      justify-content: space-between;
      padding-bottom: 17px;
      .ldf-top-left {
        .logo_view {
          width: 160px;
          height: 44px;
          display: flex;
          align-items: center;
          justify-content: center;
          .logo_text {
          }
          .logo_img {
            width: 100%;
            height: 100%;
          }
        }
        & > p {
          font-size: 16px;
          color: rgba(255, 255, 255, 0.6);
          line-height: 24px;
          margin: 20px 0 0;
          &:first-child {
            color: #ffffff;
            font-size: 20px;
            margin-top: 0;
          }
          &:nth-child(2) {
            margin-top: 30px;
          }
        }
      }
    }
  }

  .layout-mobile-footer {
    background-color: #533cb3;
    box-sizing: border-box;
    color: rgba(255, 255, 255, 0.9);
    padding: 3rem 2rem 3rem;
    width: 100%;
    .lmf-mid {
      box-sizing: border-box;
      // padding: 3rem 0;
      & > p {
        font-size: 1.75rem;
        line-height: 2.5rem;
      }
    }
  }
}
</style>
