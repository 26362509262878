const isH5 = {
    state: {
        browser: {},
        watchLoad: 1
    },
    getters: {
        getLocBrowser: (state) => state.browser,
        getWatchLoad: (state) => state.watchLoad,
    },
    mutations: {
        SET_BROWSER: (state, browser) => {
            state.browser = browser
        },
        SET_WATCHLOAD: (state, watchLoad) => {
            state.watchLoad = watchLoad
        },
    },
    actions: {
        // 获取设备
        getBrowser ({ commit, state }) {
            const ua = navigator.userAgent,
                isWindowsPhone = /(?:Windows Phone)/.test(ua),
                isSymbian = /(?:SymbianOS)/.test(ua) || isWindowsPhone,
                isAndroid = /(?:Android)/.test(ua),
                isFireFox = /(?:Firefox)/.test(ua),
                isTablet = /(?:iPad|PlayBook)/.test(ua) || (isAndroid && !/(?:Mobile)/.test(ua)) || (isFireFox && /(?:Tablet)/.test(ua)),
                isPhone = /(?:iPhone)/.test(ua) && !isTablet,
                isPc = !isPhone && !isAndroid && !isSymbian;
            var uaSateObj = {
                isTablet: isTablet,
                isPhone: isPhone,
                isAndroid: isAndroid,
                isPC: isPc,
                isH5: isPhone || isAndroid || isTablet
            }
            commit('SET_BROWSER', uaSateObj)
        }
    },
}
export default isH5