<template>
  <div class="header_view">
    <div class="header-height ld-header" v-if="!browser.isH5" id="GlobalHeader">
      <header
        class="layout-desktop-header"
        style="
          color: rgb(51, 51, 51);
          background-color: rgb(255, 255, 255);
          box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.06);
        "
      >
        <div class="ldh-left">
          <!-- <div class="logo_view">
            <img src="../assets/images/logo.png" alt="" class="logo_img" />
          </div> -->
          <div class="ldh-left-nav" style="margin-left: 0">
            <div
              class="nav_item"
              v-for="(item, index) in watchLangMenu"
              :key="index"
            >
              <a
                class="router_link"
                :class="{ 'ldh-left-nav-active': showSelectTab(item) }"
                @click="clickNav(item, index)"
                >{{ item.menu }}</a
              >
            </div>
            <a
              href="/postalPay/contract.html"
              target="_blank"
              class="ldh-left-nav-ts"
            >
              用戶協議
            </a>
            <a
              href="/postalPay/privacy.html"
              target="_blank"
              class="ldh-left-nav-ts"
            >
              隱私協議
            </a>
          </div>
        </div>
        <div class="ldh-right">
          <a
            href="http://luckygame88.com/pay/"
            target="_blank"
            class="ldh-right-recharge"
            style="border-color: rgba(31, 28, 28, 0.1)"
          >
            <img src="../assets/images/money-zs.png" alt="" />
            <i>充值</i>
          </a>
        </div>
      </header>
    </div>

    <div class="header-height lm-header" v-if="browser.isH5" id="GlobalHeader">
      <header class="layout-mobile-header">
        <div
          class="lmh-content lmh-content-1"
          style="
            color: rgb(51, 51, 51);
            background-color: rgb(255, 255, 255);
            box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.06);
          "
        >
          <!-- <div class="logo_view">
            <img src="../assets/images/logo.png" alt="" class="logo_img" />
          </div> -->
          <!-- <div class="lmh-blank"></div> -->
          <a
            href="/postalPay/contract.html"
            target="_blank"
            class="ldh-left-nav-ts"
          >
            用戶協議
          </a>
          <a
            href="/postalPay/privacy.html"
            target="_blank"
            class="ldh-left-nav-ts"
          >
            隱私協議
          </a>
          <a
            class="lmh-diamon"
            style="border-color: rgba(31, 28, 28, 0.1)"
            href="http://luckygame88.com/pay/"
            target="_blank"
          >
            <img src="../assets/images/money-zs.png" />充值
          </a>
          <template v-if="!lmPopShow">
            <img
              src="../assets/images/header_menu_black.png"
              alt="menu"
              class="lmh-menu"
              @click="changLmPopShow"
            />
          </template>
          <img
            v-else
            src="../assets/images/header_close.png"
            alt="menu"
            class="lmh-menu"
            @click="changLmPopShow"
          />
        </div>
      </header>
    </div>

    <!-- 移动端导航弹窗 -->
    <van-popup
      :overlay="true"
      class="lm_pop_pop"
      v-model="lmPopShow"
      position="right"
      :duration="0.1"
      :style="{ height: '100%', width: '90%' }"
    >
      <section class="layout-mobile-menu">
        <div class="lmm-nav">
          <div
            @click="changLmPopShow"
            v-for="(item, index) in watchLangMenu"
            :key="index"
          >
            <a class="lmm-nav-item" @click="clickNav(item, index)">
              <i>{{ item.menu }}</i>
              <span>
                <!-- <img src="../assets/images/common_right_arrow.png" alt="arrow"/> -->
                <van-icon name="arrow" size="1.5rem" />
              </span>
            </a>
          </div>
        </div>
      </section>
    </van-popup>
  </div>
</template>

<script>
import { mapState, mapMutations, mapActions } from "vuex";
export default {
  name: "GlobalHeader",
  data () {
    return {
      navIndex: 0,
      selectLink: '/home/index',
      watchLink: '/home/index',//默認監聽路由
      selectElementId: 'home-dom',//默認選中ID
      scrollViewTop: 0,
      lmPopShow: false,
      lmmPicker: false,
      langItem: {},
      watchLangMenu: [
        { menu: '主頁', link: "/home/index", elementId: 'home-dom' },
        { menu: '我們的遊戲', link: "/home/index", elementId: 'midde-section' },
        { menu: '規則說明', link: "/about/index", elementId: '' },
        { menu: '關於我們', link: "/home/index", elementId: 'footer_view' },
      ]
    };
  },
  components: {
  },
  created () {
  },
  computed: {
    ...mapState({
      browser: (state) => state.isH5.browser,
      watchLoad: (state) => state.isH5.watchLoad,
    }),
    selectTrue () {

    }
  },
  watch: {
  },
  mounted () {

  },
  destroyed () {
  },
  methods: {
    showSelectTab (data) {
      if ((this.watchLink == this.$route.path) && this.selectElementId) {
        if (this.selectElementId == data.elementId) {
          return true
        }
      } else {
        if (data.link == this.$route.path) {
          return true
        }
      }
    },
    changLmPopShow () {
      this.lmPopShow = !this.lmPopShow
    },
    scrollToElement (elementId) {
      if (!elementId) return
      this.$nextTick(() => {
        this.selectElementId = elementId
        const targetElement = document.getElementById(elementId);
        const dom_el = document.getElementById('dom_el');
        const globalHeaderHeight = document.getElementById('GlobalHeader').offsetHeight
        const offset = targetElement.offsetTop - globalHeaderHeight;
        dom_el.scrollTo({ top: offset, behavior: 'smooth' });
        // targetElement.scrollIntoView({ top: offset, behavior: 'smooth' });
      })
    },
    clickNav (data, index) {
      // console.log(data)
      this.navIndex = index
      // this.selectLink = data.link
      if (this.watchLink == data.link) {
        if (data.link != this.$route.path) {
          this.$router.push({
            path: data.link
          }).then(() => {
            this.scrollToElement(data.elementId)
          })
        } else {
          this.scrollToElement(data.elementId)
        }
      } else {
        if (data.link == this.$route.path) return
        this.$router.push({
          path: data.link
        })
      }
    }
  },
};
</script>

<style lang="less" scoped>
.header_view {
  .ld-header {
    box-sizing: border-box;
    height: 116px;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: 9999;
    .layout-desktop-header {
      box-sizing: border-box;
      display: flex;
      font-size: 18px;
      font-weight: 500;
      height: 100%;
      justify-content: space-between;
      padding: 0 calc(50% - 494px);
      // padding: 0 calc(50% - 640px);
      position: relative;
      .ldh-left {
        display: flex;
        align-items: center;
        .logo_view {
          width: 121px;
          height: 68px;
          display: flex;
          align-items: center;
          justify-content: center;
          .logo_img {
            width: 100%;
            height: 100%;
          }
          .logo_text {
            color: #333333;
            font-family: Inter-Bold;
            font-size: 40px;
          }
        }
        .ldh-left-nav {
          margin-left: 79px;
          display: flex;
          align-items: center;
          .nav_item {
            margin-right: 48px;
            .router_link {
              display: inline-block;
              font-size: 16px;
              font-family: PingFang TC;
              font-weight: 400;
              height: 66px;
              line-height: 66px;
              position: relative;
              cursor: pointer;
              &:hover {
                opacity: 1;
                color: #7e27a3;
              }
              &.ldh-left-nav-active {
                color: #7e27a3;
                opacity: 1 !important;
                &::after {
                  background-color: #7e27a3;
                  border-radius: 1px;
                  left: 50%;
                  bottom: 4px;
                  transform: translateX(-50%);
                  content: ' ';
                  display: inline-block;
                  height: 2px;
                  position: absolute;
                  width: 30px;
                }
              }
            }
          }
          .ldh-left-nav-ts {
            font-weight: 400;
            margin-right: 48px;
            &:hover {
              opacity: 1;
              color: #7e27a3;
            }
          }
        }
      }
      .ldh-right {
        align-items: center;
        display: flex;
        flex-shrink: 0;
        justify-content: flex-end;
        box-sizing: border-box;
        .ldh-right-recharge {
          align-items: center;
          border: 1px solid;
          border-radius: 20px;
          box-sizing: border-box;
          cursor: pointer;
          display: flex;
          height: 40px;
          justify-content: center;
          padding: 0 17px;
          & > img {
            height: auto;
            width: 24px;
          }
          & > i {
            margin-left: 4px;
            font-style: normal;
            font-weight: 600;
          }
        }
      }
    }
  }

  .lm-header {
    width: 100%;
    height: 7.25rem;
    position: absolute;
    top: 0;
    z-index: 9999;
    .layout-mobile-header {
      font-size: 1.75rem;
      color: #313131;
      flex-shrink: 0;
      width: 100%;
      box-sizing: border-box;
      height: 100%;
      position: relative;
      .lmh-content {
        align-items: center;
        display: flex;
        justify-content: space-between;
        padding: 0 2rem;
        box-sizing: border-box;
        height: 100%;
        position: relative;
        &::after {
          left: 2rem;
          bottom: 0;
          content: ' ';
          display: block;
          position: absolute;
          width: calc(100% - 4rem);
        }
        .logo_view {
          width: auto;
          height: 5.25rem;
          display: flex;
          align-items: center;
          justify-content: center;
          .logo_text {
          }
          .logo_img {
            width: 100%;
            height: 100%;
          }
        }
        .lmh-blank {
          flex-grow: 1;
        }
        .lmh-diamon {
          align-items: center;
          border-radius: 2.5rem;
          border-style: solid;
          border-width: 0.125rem;
          display: flex;
          height: 3.75rem;
          padding: 0 1.25rem;
          & > img {
            margin-right: 0.375rem;
            height: 3rem;
            width: 3rem;
          }
        }
        .lmh-menu {
          margin-left: 1.25rem;
          height: 3.5rem;
          width: 3.5rem;
        }
        .ldh-left-nav-ts {
          margin-right: 1.25rem;
        }
      }
    }
  }

  .lm_pop_pop {
    // z-index: 500 !important;
    background-color: transparent;
    .layout-mobile-menu {
      padding-top: 7.25rem;
      box-sizing: border-box;
      background-color: rgba(0, 0, 0, 0.4);
      color: #fff;
      height: 100%;
      width: 100%;
      overflow-y: auto;
      .lmm-nav {
        .lmm-nav-item {
          display: flex;
          justify-content: space-between;
          align-items: center;
          padding: 2.125rem 2rem;
          position: relative;
          & > i {
            display: flex;
            font-size: 2rem;
            font-style: normal;
            font-weight: 500;
            line-height: 2.75rem;
            align-items: center;
          }
          & > span {
            align-items: center;
            display: flex;
            & > img {
              margin-left: 0.75rem;
              height: 1.5rem;
              width: 1.5rem;
            }
          }
          &::after {
            background: rgba(255, 255, 255, 0.3);
            bottom: 0;
            content: ' ';
            height: 0.0625rem;
            position: absolute;
            transform: matrix(1, 0, 0, -1, 0, 0);
            width: 100%;
            left: 0;
          }
        }
      }
    }
  }
}
</style>
