<template>
  <div class="" :class="{'layout-desktop':!browser.isH5,'layout-mobile':browser.isH5}">
    <GlobalHeader />
    <section id="dom_el" class="dom_el" :class="{'ld-body': !browser.isH5,'lm-body':browser.isH5}">
      <router-view />
      <GlobalFooter />
    </section>
  </div>

</template>

<script>
import { mapState, mapMutations, mapActions } from "vuex";
import GlobalHeader from '@/components/GlobalHeader'
import GlobalFooter from '@/components/GlobalFooter'

export default {
  name: 'BasicLayout',
  components: {
    GlobalHeader,
    GlobalFooter
  },
  computed: {
    ...mapState({
      browser: (state) => state.isH5.browser,
    }),
  },
  data () {
    return {
    }
  },
  created () {
  },
  mounted () {
    console.log(this.browser)
  },
  methods: {
  }
}
</script>
<style lang="less" scoped>
.layout-desktop{
  min-width: 1280px;
  overflow: auto;
  position: relative;
  width: 100%;
  box-sizing: border-box;
  .ld-body{
    height: 100vh;
    min-height: 500px;
    overflow: auto;
    width: 100%;
    box-sizing: border-box;
  }
}

.layout-mobile{
  box-sizing: border-box;
  width: 100%;
  display: flex;
  flex-direction: column;
  position: relative;
  overflow: auto;
  .lm-body{
    height: 100vh;
    overflow: auto;
    overflow-x: hidden;
    position: relative;
    width: 100%;
  }
}
</style>