<template>
  <div id="app">
    <router-view :key="watchLoad"></router-view>
  </div>
</template>

<script>
import { mapState, mapMutations, mapActions } from "vuex";
export default {
  name: 'App',
  computed: {
    ...mapState({
      watchLoad: (state) => state.isH5.watchLoad,
    }),
  },
  methods: {
  }
}
</script>

<style lang="less" scoped>
</style>
