import Vue from 'vue'
import Vuex from 'vuex'
// import axios from 'axios'
// import qs from 'qs'

Vue.use(Vuex)
import isH5 from "./module/isH5_module"
const actions = {
}

const mutations = {
    
}

const state = {
    loginUser: null,
}

export default new Vuex.Store({
    actions,
    mutations,
    state,
    modules: {
        isH5,
    }
})